import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'scanmap',
    loadChildren: () => import('./home/pages/scanmap/scanmap.module').then( m => m.ScanMapModule)
  },
  {
    path: 'editmap',
    loadChildren: () => import('./home/pages/editmap/editmap.module').then( m => m.EditMapModule)
  },
  {
    path: 'teachui-wizard',
    loadChildren: () => import('./teachui-wizard/teachui-wizard.module').then(m => m.TeachuiWizardPageModule)
  },
  {
    path: 'measurevis',
    loadChildren: () => import('./home/pages/measurevis/measurevis.module').then( m => m.MeasurevisPageModule)
  },
  {
    path: 'teachroute',
    loadChildren: () => import('./home/pages/teachroute/teachroute.module').then( m => m.TeachroutePageModule)
  },
  {
    path: 'teachuivisservices',
    loadChildren: () => import('./services/teachuivisservices/teachuivisservices.module').then(m => m.TeachuivisservicesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
