import { ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { QWebChannelConnection } from './teachui_qweb-channel.service';
import { SessionService } from './teachui_qwebchannel_interfaces';

export class Teachui_sessionServiceManager< T extends SessionService >
{
  public serviceAvailable = new ReplaySubject< Teachui_sessionServiceManager< T > >( null );

  private acquired = false;
  private alert: any = null;
  private service_: T = null;

  private serviceCanAcquire = new Subject<boolean>();  // This subject is fired every time the service is acquired

  /**
   * Create a new SessionManager
   * @param serviceName The name of the service
   */
  constructor( private serviceName: string,
               private connection: QWebChannelConnection )
  {
    connection.webChannelOpened.subscribe( () => this.init() );
    connection.userLoggedOut.subscribe( () => this.reset() );
  }

  private init()
  {
    this.connection.waitForService< T >( this.serviceName ).then( service =>
    {
      this.service_ = service;
      this.serviceAvailable.next( this );

      service.canAcquire.connect( () => this.serviceCanAcquire.next( true ) );
    });
  }

  private reset()
  {
    this.service_ = null;
    this.acquired = false;

    if ( this.alert )
    {
      this.alert.dismiss();
      this.alert = null;
    }
  }

  get service()
  {
    return this.service_;
  }

  isValid(): boolean
  {
    return this.service != null;
  }

  isAcquired(): boolean
  {
    return ( this.isValid() && this.acquired );
  }

  /**
   * Acquire this service
   *
   * The User gets informed if the access to the service was denied or
   * if the access is granted.
   *
   * @note The Promise is resolved directly.
   */
  async acquire(): Promise< boolean >
  {
    return this.acquireImpl( false, true );
  }

  /**
   * Acquire this service
   *
   * The User gets informed when the access to the service is granted.
   *
   * @note The Promise is resolved only after the service got acquired.
   */
  async acquireWait(): Promise< boolean >
  {
    return this.acquireImpl( true, true );
  }

  /**
   * Silently acquire this service
   *
   * @note The Promise is resolved directly.
   * @note No messages get displayed to the user
   */
  async acquireSilent(): Promise< boolean >
  {
    return this.acquireImpl( false, false );
  }

  /**
   * Silently acquire this service
   *
   * @note The Promise is resolved only after the service got acquired.
   * @note No messages get displayed to the user
   */
  async acquireSilentWait(): Promise< boolean >
  {
    return this.acquireImpl( true, false );
  }


  /**
   * The concrete implementation for acquire
   *
   * @param wait Wait until the service got acquired
   * @param enableNotifications Enable outputs for the user
   */
  private async acquireImpl( wait: boolean, enableNotifications: boolean ): Promise< boolean >
  {
    return new Promise< boolean >( resolve =>
    {
      let noAccess = false;

      // declare the acquiredHandler --------------------------------------------
      const acquireHandler = async ( acquired: boolean ) =>
      {
        // only show infos if notifications are enabled -------
        if ( enableNotifications )
        {
          if ( !acquired )
          {
            this.alert = await this.connection.alertController.create({
              header: 'Access Denied!',
              subHeader: 'An other User is currently using this function!',
              buttons: ['OK']
            });

            await this.alert.present();
            noAccess = true;
          }

          if ( noAccess && acquired )
          {
            noAccess = false;

            if ( this.alert )
            {
              await this.alert.dismiss();
            }

            const toast = await this.connection.toastController.create({
              message: 'You have access now!',
              duration: 3000,
              buttons: [
                {
                  text: 'Done',
                  role: 'cancel',
                }
              ]
            });

            await toast.present();
          }
        }
        // -----------------------------------------------------------

        // update internal acquired state
        this.acquired = acquired;

        // if the service was acquired -> resolve with true
        if ( acquired )
        {
          resolve( true );
        }
        // if the handler could not be acqiured but we should only resolve if we have acquired it -> subscribe to event
        else if ( wait )
        {
          // the given handler is called every time the service is released and can be acquired by someone else
          this.serviceCanAcquire.pipe( take(1) ).subscribe( ok =>
          {
            // if the service got acquired during our wait -> resolve with true
            if ( this.isAcquired() )
            {
              resolve( true );
            }
            // if the service was released -> resolve with false
            else if ( !ok )
            {
              resolve( false );
            }
            // if we must acquire the service -> call remote service
            else
            {
              this.service.acquire( acquireHandler );
            }
          });
        }
        // if the acquire failed and we should not wait -> resolve with false
        else
        {
          resolve( false );
        }
      };

      // check if service is valid ------------------------------------------
      if ( this.isValid() )
      {
        // if the service is already acquired -> call handler
        if ( this.isAcquired() )
        {
          resolve( true );
        }
        // if we must acqurie the service -> call remote service
        else
        {
          this.service.acquire( acquireHandler );
        }
      }
      else
      {
        if ( enableNotifications )
        {
          this.connection.alertController.create({
            header: 'Unsupported Function!',
            subHeader: 'You do not have access to the function you requested!',
            buttons: ['OK']
          }).then( alert => alert.present() );
        }

        // it the service is currently not available but we want to wait -> subscribe to the first element of serviceAvailable
        if ( wait )
        {
          this.serviceAvailable.pipe( take(1) ).subscribe( () => {
            this.acquireImpl( wait, enableNotifications ).then( value => resolve( value ) );
          });
        }
        // if the service is not available -> resolve to false
        else
        {
          resolve( false );
        }
      }
    });
  }

  /**
   * Release the service and call resolve on all open acquire Promises with false
   */
  release(): void
  {
    if ( this.isValid() )
    {
      this.service.release();
    }

    this.acquired = false;

    // inform all open subscriptions
    this.serviceCanAcquire.next( false );
  }
}
