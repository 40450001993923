import { Component } from '@angular/core';
import { Teachui_qwebChannelService } from './services/qwebchannel/teachui_qweb-channel.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],

})
export class AppComponent {
  constructor(private wc: Teachui_qwebChannelService )
  {
    wc.init();
  }
}
