import { Injectable, Inject } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Subject, Unsubscribable } from 'rxjs';
import { Platform, ToastController, AlertController } from '@ionic/angular';

import { AuthenticationService, SuccessCallback, QWebChannelConfig, SessionService } from './teachui_qwebchannel_interfaces';
import { Teachui_qwebchannel } from './teachui_qwebchannel';

import { Teachui_sessionServiceManager } from './teachui_session-service-manager';

class WaitHandlerData
{
  constructor( public service: string, public handler: any )
  {
  }
}

class ConditionSubject< T >
{
  private lastValue: T;
  private hasLast = false;
  private subject = new Subject< T >();

  constructor( private condition: () => boolean )
  {
  }

  subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Unsubscribable
  {
    if ( this.condition() && next && this.hasLast )
    {
      next( this.lastValue );
    }

    return this.subject.subscribe( next, error, complete );
  }

  next(value?: T): void
  {
    this.lastValue = value;
    this.hasLast = true;
    this.subject.next( value );
  }
}

/**
 * This class represents a real connection to a Teachui_qwebchannel Server
 */
export class QWebChannelConnection
{
  webSocket: WebSocketSubject<any> = null;

  private channelConnected: boolean;
  private authService: AuthenticationService = null;

  private username: string;
  private apis: {} = null;
  private userApis: {} = null;

  private waitHandlers: Array< WaitHandlerData > = [];

  private tryCount = 0;
  private timeoutTimer: number = null;

  /**
   * The webChannelOpened Subject calls the next() method every time the webChannel establishes a new connection.
   * It allso calls the next() function on subscribe() once if the connection is already established.
   */
  public webChannelOpened = new ConditionSubject< void >( () => this.isConnected() );

  /**
   * This Subject calls next() every time the connection to the channel is closed
   */
  public webChannelClosed = new Subject< void >();

  /**
   * This Subject is called every time the user gets logged in.
   * It allso calls the next() function on subscribe() once if the user is already logged in.
   */
  public userLoggedIn  = new ConditionSubject< string >( () => this.isLogedIn() );

  /**
   * This Subject is called every time the user gets logged out.
   *
   * @note this Subject is also called if the webChannel is closed!
   */
  public userLoggedOut = new Subject< string >();

  /**
   * Indicate if this QWebChannelConnection is disposed
   */
  private isDisposed = false;

  /**
   * The actual Teachui_qwebchannel object
   */
  private channel: Teachui_qwebchannel;

  constructor( public hostname: string,
               public port: number,
               public toastController: ToastController,
               public alertController: AlertController,
               private simMode: boolean,
               private heartbeatRate: number )
  {
    if ( !simMode )
    {
      this.initWebChannel();
    }
  }

  /**
   * Set the service map which is available if the connection is NOT established.
   * If a connection is esablished, this services gets replaced
   * @internal
   *
   * @param authService The service to be used for authentification
   * @param services The map of services
   */
  setSimServices( authService: AuthenticationService, services: {} )
  {
    this.authService = authService;
    this.apis = services;

    this.channelConnected = true;
    this.webChannelOpened.next();
  }

  /**
   * Check if the channel is in simulation mode
   * @returns TRUE if simulation mode is activated
   */
  get simulationMode(): boolean
  {
    return this.simMode;
  }

  private checkConnected(): void
  {
    if ( !this.channelConnected && this.timeoutTimer === null && !this.isDisposed )
    {
     // console.log( 'checkConnected -> initWebChannel: ' + this.tryCount );
      this.initWebChannel();
      this.tryCount++;

      this.timeoutTimer = window.setTimeout( () => {
        this.timeoutTimer = null;
        this.checkConnected();
        // AR:  intially 5000
      }, 500000);
    }
  }

  private initWebChannel(): void
  {
    // Define the handler that should be called if a WebSocket connection is established
    const handleConnectionOpened = ( msg ) =>
    {
       // console.log( 'WebSocket connected, setting up Teachui_qwebchannel.');

      this.channel = new Teachui_qwebchannel( this.webSocket, (ch ) => {
         // console.log( 'Opened Teachui_qwebchannel' );

        this.channelConnected = true;

        this.apis = ch.objects;  // save all default apis
        this.authService = ch.objects['auth']; // save auth api in special object
        this.checkWaitHandlers();

        this.webChannelOpened.next();
      }, this.heartbeatRate );
    };

    // Define the handler that should be called if a WebSocket connection is closed
    const handleConnectionClosed = ( msg ) => {
      // first call user logout function
      this.logout();

      // update channelConnected state
      const wasConnected = this.channelConnected;
      this.channelConnected = false;

      console.error( 'WebSocket closed! Error code: ' + msg.code );

      // cleanup the actual channel if the websocket connection was closed
      if ( this.channel )
      {
        this.channel.dispose();
        this.channel = null;
      }

      // if we were connected -> send the channel close event
      if ( wasConnected )
      {
        this.userLoggedOut.next();
        this.webChannelClosed.next();
      }

      if ( this.webSocket )
      {
        this.webSocket.complete();
        this.webSocket = null;
      }

      this.checkConnected();
    };

    // check if we have already a websocket created
    if ( !this.webSocket )
    {
      this.webSocket = webSocket({
        url: 'ws://' + this.hostname + ':' + this.port,
        openObserver: {
          next: msg => {
            handleConnectionOpened( msg );
          }
        },
        closeObserver: {
          next: msg => {
            handleConnectionClosed( msg );
          }
        },
      });
    }

    // subscribe to webSocket so that the connection is esablished
    this.webSocket.subscribe(
      () => {},
      ( error ) => {
        handleConnectionClosed( error );  // if an error is detected -> we close the connection
      },
      () => {}
    );
  }

  private createApiMap( list )
  {
    if ( !list || list.length === 0 )
    {
      return null;
    }

    const apis = {};

    for ( let i = 0; i < list.length; ++i )
    {
      apis[ list[i].serviceName ] = list[i];
    }

    return apis;
  }

  /**
   * Dispose the connection and all acquired resources.
   * This also logs the user out
   */
  dispose(): void
  {
     // console.log( 'dispose: ' + this.hostname + ':' + this.port );
    this.isDisposed = true;

    this.logout();
    window.clearTimeout( this.timeoutTimer );
  }

  /**
   * Check if the connection to the server is esablished
   * @returns TRUE if a connection is established
   */
  isConnected(): boolean
  {
    this.checkConnected();
    return this.channelConnected;
  }

  /**
   * Tries to login the user on the Teachui_qwebchannel server instance.
   *
   * @param username The username of the user to login
   * @param password The password of the user
   * @returns A promise that evaluates to a boolean (TRUE = user is logged in)
   */
  async login( username: string, password: string ): Promise< boolean >
  {
    return new Promise< boolean >( ( resolve, reject ) =>
    {
      if ( this.channelConnected && this.authService )
      {
        this.authService.login( username, password, ( list ) =>
        {
          this.userApis = this.createApiMap( list );
          this.checkWaitHandlers();

          // This is an optional debug output to show the received services
          //  // console.log( this.userApis );

          if ( this.isLogedIn() )
          {
            this.username = username;
            this.userLoggedIn.next( username );
          }

          resolve( this.isLogedIn() );
        });
      }
      else
      {
        reject('Not connected');
      }
    });
  }

  /**
   * Log the user out on the server.
   * This also removes the additional service that where available for the logged in user
   */
  logout(): void
  {
    if ( this.channelConnected && this.authService )
    {
      // save the username for the event
      const username = this.username;

      this.authService.logout();
      this.userApis = null;
      this.username = null;

      // call the event after logout was performed
      this.userLoggedOut.next( username );
    }
    else
    {
       // console.log( 'Not connected' );
    }
  }

  /**
   * Change the password of the given user to the given value
   * @param username The user whose password should be changed
   * @param password The new password
   * @return Promise that evaluates to boolean
   */
  changePassword( username: string, password: string, callback: SuccessCallback ): void
  {
    console.warn( username + '->' + password );

    if ( this.channelConnected && this.authService )
    {
      this.isConnected();
      console.warn( 'this.channelConnected && this.authService' );

      this.authService.changePassword( username, password, ( ok: boolean ) => {
        callback( ok );
      });
    }
    else
    {
       // console.log( 'Not connected to auth service' );
    }
  }

  /**
   * Checks if the user is loged in;
   */
  isLogedIn(): boolean
  {
    return this.userApis != null;
  }

  /**
   * Get the username
   * @returns The username or an empty string
   */
  getUserName(): string
  {
    if ( this.isLogedIn() )
    {
      return this.username;
    }

    return '';
  }

  getService< T = any >( name: string ): T
  {
    let service: T = null;

    if ( this.channelConnected )
    {
      // first check for user apis -> these can overwrite default apis
      if ( !service && this.userApis )
      {
        service = this.userApis[name];
      }

      // default apis
      if ( !service && this.apis )
      {
        service = this.apis[name];
      }
    }

    return service;
  }

  getServiceNames()
  {
    return Object.keys( this.userApis );
  }

  /**
   * Wait until a service with the given name is available
   *
   * @param serviceName The name of the service
   * @returns A Promise object that is resolved when the service with the given serviceName is available
   */
  async waitForService<T>( serviceName: string ): Promise< T >
  {
    return new Promise< T >( (resolve) =>
    {
      // if service is already known
      if ( this.hasService( serviceName ) )
      {
        resolve( this.getService( serviceName ) );
      }
      // wait for service
      else
      {
        this.waitHandlers.push( new WaitHandlerData( serviceName, resolve ) );
      }
    });
  }

  /**
   * Create a new SessionService Manager object for the given service.
   *
   * The object is returned directly but the service
   *
   * @param service The name of the service for which a Teachui_sessionServiceManager is created
   * @returns A new Teachui_sessionServiceManager instance that allows to acquire/release the service for exclusive access
   */
  createSessionServiceManager<T extends SessionService >( service: string ): Teachui_sessionServiceManager< T >
  {
    return new Teachui_sessionServiceManager< T >( service, this );
  }

  /**
   * Check if the QWebChannelConnection has a service with the given name
   * @param serviceName The name of the service
   */
  hasService( serviceName: string ): boolean
  {
    return ( this.getService( serviceName ) != null );
  }

  /**
   * This methods checks if a service for a registerd WaitHandler is available.
   * If the service is available the Handler is called with the correct service instance
   */
  private checkWaitHandlers()
  {
    this.waitHandlers.forEach( el =>
    {
      if ( this.hasService( el.service ) && el.handler )
      {
       el.handler( this.getService( el.service ) );
       el.handler = null;
      }
    });

    // remove all completed wait handlers
    this.waitHandlers = this.waitHandlers.filter( ( el ) => el.handler != null );
  }
}


// ------------------------------------------------
//
//  Teachui_qwebChannelService
//
// ------------------------------------------------
@Injectable({
  providedIn: 'root'
})
export class Teachui_qwebChannelService
{
  private connections = new Map< string, QWebChannelConnection >();

  private defaultHostname: string;
  private defaultPort: number;

  private heartbeatRate: number = 3000;

  private simMode = false;
  public simAuthService: AuthenticationService;
  public simServices: {};

  public default: QWebChannelConnection;

  private config: QWebChannelConfig;

  constructor( private platform: Platform,
               private toastController: ToastController,
               private alertController: AlertController )
               {}

  init( config: QWebChannelConfig = new QWebChannelConfig() )
  {
    this.config = config;
    //  INMACH ULM
    // WLLAN IMACH AGV
    //  PW   bia5UPswWofBURNyxXAl
   //  this.defaultHostname = '10.150.5.3';
    // INMACH ULM AGV
      this.defaultHostname = '94.130.179.181';
    // FS Server
   // this.defaultHostname = '85.215.94.31';
   // this.defaultHostname = window.location.hostname || 'localhost';  WS
     this.defaultPort     = 8082;
     // wss t Port = 443
     // this.defaultPort =443;
    if ( this.config )
    {
      // if we are on a mobile device and want to detect it
      if ( this.config.detectMobile && this.platform && this.platform.is( 'hybrid' ) )
      {
        this.defaultHostname = this.config.host;
      }

      // if an other default port is given
      if ( this.config.port )
      {
        this.defaultPort = this.config.port;
      }

      // if we want to force the given host
      if ( this.config.forceHost )
      {
        this.defaultHostname = this.config.host;
      }

      if ( this.config.heartbeatRate )
      {
        this.heartbeatRate = this.config.heartbeatRate;
      }
    }

    if ( !this.config.disableDefault )
    {
      this.default = new QWebChannelConnection( this.defaultHostname,
                                                this.defaultPort,
                                                this.toastController,
                                                this.alertController,
                                                this.simMode,
                                                this.heartbeatRate );

      if ( this.simMode )
      {
        this.default.setSimServices( this.simAuthService, this.simServices );
      }
    }

    if ( this.config.simulation )
    {
      this.simMode = this.config.simulation;
    }

    const key = this.defaultHostname + ':' + this.defaultPort;
    this.connections.set( key, this.default );
  }

  connection( host: string, port: number = this.defaultPort ): QWebChannelConnection
  {
    const key = host + ':' + port;

    if ( !this.connections.has( key ) )
    {
      const con = new QWebChannelConnection( host,
                                             port,
                                             this.toastController,
                                             this.alertController,
                                             this.simMode,
                                             this.heartbeatRate );

      if ( this.simMode )
      {
        con.setSimServices( this.simAuthService, this.simServices );
      }

      this.connections.set( key, con );
    }

    return this.connections.get( key );
  }

  get simulationMode(): boolean
  {
    return this.simMode;
  }

}
