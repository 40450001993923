// ------------------------------------------------
//
//  Interfaces
//
// ------------------------------------------------
export type SuccessCallback = (success: boolean) => any;


export interface QObject
{
  objectName: string;

  destroyed: QConnect< QObject >;
  objectNameChanged: QConnect< string >;

  deleteLater(): void;
}

/**
 *
 */
export interface QConnection
{
   disconnect(): void;
}

/**
 *
 */
export interface QConnect<T>
{
  connect( handler: (arg0: T) => any ): QConnection;
  disconnect( handler: (arg0: T) => any ): void;
  disconnectAll(): void;
}

/**
 * The interface for all SessionServices
 *
 */
export interface SessionService extends QObject
{
  canAcquire: QConnect<void>;

  acquire( handler: SuccessCallback ): void;
  release(): void;

  getServiceName( handler: ( name: string ) => any ): void;
}

/**
 *
 */
export interface AuthenticationService extends QObject
{
  login( username: string, password: string, handler: ( result: any ) => any ): void;
  logout(): void;
  changePassword( username: string, password: string, handler: SuccessCallback ): void;
}

/**
 *
 */
export class QWebChannelConfig
{
  disableDefault: boolean = false;
  host: string;
  port: number;

  // set this to true so that on mobile devices the given host is used.
  // Otherwise the ip where the page was loaded from is used.
  detectMobile: boolean = true;

  simulation: boolean = false;

  // indicate if the given host should always be used
  forceHost: boolean = false;

  // the Rate at which the heartbeat must come [in milliseconds]
  heartbeatRate: number = 40000;
}
