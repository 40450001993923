import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

// Import the module from the SDK
import { AuthModule } from '@auth0/auth0-angular';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Teachui_qwebChannelService } from './services/qwebchannel/teachui_qweb-channel.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, HttpClientModule, IonicModule.forRoot(), AppRoutingModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: 'costimizer.eu.auth0.com',
      clientId: 'bSXaJ3BViF7II88qmZlveRIQBmO2L5NI'
    }),
  ],
  providers: [
    Teachui_qwebChannelService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
