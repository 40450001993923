import { WebSocketSubject } from 'rxjs/webSocket';

/****************************************************************************
**
** Copyright (C) 2015 The Qt Company Ltd.
** Copyright (C) 2014 Klarälvdalens Datakonsult AB, a KDAB Group company, info@kdab.com, author Milian Wolff <milian.wolff@kdab.com>
** Contact: http://www.qt.io/licensing/
**
** This file is part of the QtWebChannel module of the Qt Toolkit.
**
** $QT_BEGIN_LICENSE:LGPL21$
** Commercial License Usage
** Licensees holding valid commercial Qt licenses may use this file in
** accordance with the commercial license agreement provided with the
** Software or, alternatively, in accordance with the terms contained in
** a written agreement between you and The Qt Company. For licensing terms
** and conditions see http://www.qt.io/terms-conditions. For further
** information use the contact form at http://www.qt.io/contact-us.
**
** GNU Lesser General Public License Usage
** Alternatively, this file may be used under the terms of the GNU Lesser
** General Public License version 2.1 or version 3 as published by the Free
** Software Foundation and appearing in the file LICENSE.LGPLv21 and
** LICENSE.LGPLv3 included in the packaging of this file. Please review the
** following information to ensure the GNU Lesser General Public License
** requirements will be met: https://www.gnu.org/licenses/lgpl.html and
** http://www.gnu.org/licenses/old-licenses/lgpl-2.1.html.
**
** As a special exception, The Qt Company gives you certain additional
** rights. These rights are described in The Qt Company LGPL Exception
** version 1.1, included in the file LGPL_EXCEPTION.txt in this package.
**
** $QT_END_LICENSE$
**
****************************************************************************/

// Important Information: code below is partically Copyright InMach  used of techui integration


const QWebChannelMessageTypes = {
  heartbeat: -1,
  signal: 1,
  propertyUpdate: 2,
  init: 3,
  idle: 4,
  debug: 5,
  invokeMethod: 6,
  connectToSignal: 7,
  disconnectFromSignal: 8,
  setProperty: 9,
  response: 10,
};

interface WebChannelData
{
  type: number;
}

export class Teachui_qwebchannel
{
  private execCallbacks = {};
  private execId = 0;
  objects = new Map< string, QObject>();

  private transport: WebSocketSubject<{}>;

  private lastHeartbeat = Date.now();
  private heartbeatInterval = null;

  constructor( transport: WebSocketSubject<{}>,
               initCallback: ( instance: Teachui_qwebchannel ) => void,
               heartbeatRate = 2500 )
  {
    this.transport = transport;

    this.transport.subscribe(
      (data: WebChannelData) =>
      {
        //  console.log( 'Teachui_qwebchannel received data:', data );

        switch (data.type)
        {
          case QWebChannelMessageTypes.signal:
            this.handleSignal(data);
            break;
          case QWebChannelMessageTypes.response:
            this.handleResponse(data);
            break;
          case QWebChannelMessageTypes.propertyUpdate:
            this.handlePropertyUpdate(data);
            break;
          default:
            console.error('invalid message received:', data );
            break;
        }
      },
      () => {},
      () => {}
    );

    this.exec( { type: QWebChannelMessageTypes.init }, (data) =>
      {
        for ( const objectName1 in data )
        {
          if ( data.hasOwnProperty(objectName1) )
          {
            // tslint:disable-next-line:no-unused-expression
            new QObject( objectName1, data[objectName1], this );
          }
        }

        // now unwrap properties, which might reference other registered objects
        for ( const objectName2 in this.objects )
        {
          if ( this.objects.hasOwnProperty(objectName2) )
          {
            this.objects[objectName2].__unwrapProperties__();
          }
        }

        // generate ping messages and check for heartbeat
        this.heartbeatInterval = window.setInterval( () => {
          // check heartbeat
          if ( ( Date.now() - this.lastHeartbeat ) > 2 * heartbeatRate )
          {
            console.error( 'Heartbeat error! (Rate: ', heartbeatRate, ')' );

            // inform the local closeObserver
            const errorObj = { code: 4000, reason: 'Heartbeat Error', wasClean: false };
            this.transport.error( new CloseEvent( 'error', errorObj ) );
          }

          // send new heartbeat message
          this.exec( { type: QWebChannelMessageTypes.heartbeat }, () => {
            this.lastHeartbeat = Date.now();
          });
        }, heartbeatRate );

        if (initCallback)
        {
          initCallback(this);
        }

        this.exec({ type: QWebChannelMessageTypes.idle });
      }
    );
  }

  private send( data: any )
  {
    this.transport.next( data );
  }

  dispose()
  {
    window.clearInterval( this.heartbeatInterval );

    this.transport.unsubscribe(); // unsubscribe
    this.transport = null;

    this.objects = null;
    this.execCallbacks = null;
    this.execId = null;

     // console.log( 'Dispose Teachui_qwebchannel' );
  }

  exec( data: any, callback: ( data: any ) => void = null )
  {
    if (!callback)
    {
      // if no callback is given, send directly
      this.send(data);
      return;
    }

    if (this.execId === Number.MAX_VALUE)
    {
      // wrap
      this.execId = Number.MIN_VALUE;
    }

    if (data.hasOwnProperty('id'))
    {
      console.error( 'Cannot exec message with property id: ' + JSON.stringify(data) );
      return;
    }

    data.id = this.execId++;
    this.execCallbacks[data.id] = callback;
    this.send(data);
  }


  private handleSignal( message: any )
  {
    const object = this.objects[message.object];
    if ( object )
    {
      object.signalEmitted(message.signal, message.args);
    }
    else
    {
      console.warn( 'Unhandled signal: ' + message.object, + '::' + message.signal );
    }
  }

  private handleResponse( message: any )
  {
    if ( !message.hasOwnProperty('id') )
    {
      console.error( 'Invalid response message received:', message );
      return;
    }

    this.execCallbacks[message.id](message.data);
    delete this.execCallbacks[message.id];
  }

  private handlePropertyUpdate( message: any )
  {
    for ( const i in message.data )
    {
      if ( message.data.hasOwnProperty(i) )
      {
        const data = message.data[i];
        const object = this.objects[data.object];

        if ( object )
        {
          object.__propertyUpdate__( data.signals, data.properties );
        }
        else
        {
          console.warn( 'Unhandled property update: ' + data.object + '::' + data.signal );
        }

        this.exec( { type: QWebChannelMessageTypes.idle } );
      }
    }
  }

  debug( message: any )
  {
    this.send({ type: QWebChannelMessageTypes.debug, data: message });
  }

}


class QObject
{
  private id_: string;

  // List of callbacks that get invoked upon signal emission
  private objectSignals_ = new Map< number, Array< any > >();

  // Cache of all properties, updated when a notify signal is emitted
  private propertyCache_ = {};

  webChannel_: Teachui_qwebchannel;

  destroyed: any;

  constructor( name: string, data: any, webChannel: Teachui_qwebchannel )
  {
    this.id_ = name;
    this.webChannel_ = webChannel;

    this.webChannel_.objects[name] = this;

    data.methods.forEach( (methodData) => { this.__addMethod__(methodData); } );
    data.properties.forEach( (propertyInfo) => { this.__bindGetterSetter__(propertyInfo); } );
    data.signals.forEach( (signal) => { this.__addSignal__(signal, false); });

    for ( const el in data.enums )
    {
      if ( data.enums.hasOwnProperty(el) )
      {
        this[el] = data.enums[el];
      }
    }
  }

  // ----------------------------------------------------------------------

  __unwrapQObject__( response: any )
  {
    if (response instanceof Array)
    {
      // support list of objects
      const ret = new Array(response.length);
      for (let i = 0; i < response.length; ++i)
      {
        ret[i] = this.__unwrapQObject__(response[i]);
      }
      return ret;
    }

    if (!response
      || !response['__QObject*__']
      // tslint:disable-next-line:no-string-literal
      || response['id'] === undefined) {
      return response;
    }

    const objectId = response.id;
    if ( this.webChannel_.objects[objectId] )
    {
      return this.webChannel_.objects[objectId];
    }

    if (!response.data) {
      console.error( 'Cannot unwrap unknown QObject ' + objectId + ' without data.' );
      return;
    }

    const qObject = new QObject(objectId, response.data, this.webChannel_);
    qObject.destroyed.connect( () => {
      if (this.webChannel_.objects[objectId] === qObject) {
        delete this.webChannel_.objects[objectId];
        // reset the now deleted QObject to an empty {} object
        // just assigning {} though would not have the desired effect, but the
        // below also ensures all external references will see the empty map
        // NOTE: this detour is necessary to workaround QTBUG-40021
        const propertyNames = [];
        for ( const propertyName in qObject )
        {
          if ( qObject.hasOwnProperty(propertyName) )
          {
            propertyNames.push(propertyName);
          }
        }

        for ( const idx in propertyNames )
        {
          if ( propertyNames.hasOwnProperty(idx) )
          {
            delete qObject[propertyNames[idx]];
          }
        }
      }
    });

    // here we are already initialized, and thus must directly unwrap the properties
    qObject.__unwrapProperties__();
    return qObject;
  }

  __unwrapProperties__()
  {
    for ( const propertyIdx in this.propertyCache_ )
    {
      if ( this.propertyCache_.hasOwnProperty(propertyIdx) )
      {
        this.propertyCache_[propertyIdx] = this.__unwrapQObject__(this.propertyCache_[propertyIdx]);
      }
    }
  }

  private __addSignal__(signalData: any[], isPropertyNotifySignal: boolean)
  {
    const signalName  = signalData[0];
    const signalIndex = signalData[1];

    const disconnectFunc = (callback) =>
    {
      if (typeof (callback) !== 'function')
      {
        console.error( 'Bad callback given to disconnect from signal ' + signalName );
        return;
      }

      if ( !this.objectSignals_.has( signalIndex ) )
      {
        this.objectSignals_.set( signalIndex, new Array< any >() );
      }

      const idx = this.objectSignals_.get(signalIndex).indexOf(callback);

      if (idx === -1)
      {
        console.error( 'Cannot find connection of signal ' + signalName + ' to ' + callback.name);
        return;
      }

      this.objectSignals_.get(signalIndex).splice(idx, 1);

      if (!isPropertyNotifySignal && this.objectSignals_.get(signalIndex).length === 0)
      {
        // only required for "pure" signals, handled separately for properties in propertyUpdate
        this.webChannel_.exec({
          type: QWebChannelMessageTypes.disconnectFromSignal,
          object: this.id_,
          signal: signalIndex
        });
      }
    };

    const disconnectAllFunc = () =>
    {
      this[signalName].__callbackList__.forEach( ( func ) => { func(); } );
      this[signalName].__callbackList__.splice( 0, this[signalName].__callbackList__.length );
    };

    const connectFunc = ( callback ) =>
    {
      if (typeof (callback) !== 'function')
      {
        console.error( 'Bad callback given to connect to signal ' + signalName );
        return;
      }

      if ( !this.objectSignals_.has( signalIndex ) )
      {
        this.objectSignals_.set( signalIndex, new Array< any >() );
      }

      this.objectSignals_.get(signalIndex).push( callback );

      //  console.log( 'connectFunc', signalName, signalIndex, this );

      if (!isPropertyNotifySignal && signalName !== 'destroyed')
      {
        // only required for "pure" signals, handled separately for properties in propertyUpdate
        // also note that we always get notified about the destroyed signal
        this.webChannel_.exec({
          type: QWebChannelMessageTypes.connectToSignal,
          object: this.id_,
          signal: signalIndex
        });
      }

      this[signalName].__callbackList__.push( () => { disconnectFunc( callback ); } );

      return { disconnect: () => { disconnectFunc( callback ); } };
    };

    this[signalName] = {
      connect: connectFunc,
      disconnect: disconnectFunc,
      disconnectAll: disconnectAllFunc,

      __callbackList__: []
    };
  }

  /**
   * Invokes all callbacks for the given signalname. Also works for property notify callbacks.
   */
  private __invokeSignalCallbacks__( signalName: number, signalArgs: [] )
  {
    const connections = this.objectSignals_.get( signalName );

    //  console.log( '__invokeSignalCallbacks__', signalName, signalArgs, connections );

    if ( connections )
    {
      connections.forEach( callback => callback.apply(callback, signalArgs) );
    }
  }

  __propertyUpdate__(signals, propertyMap)
  {
    // update property cache
    for ( const propertyIndex in propertyMap )
    {
      if ( propertyMap.hasOwnProperty(propertyIndex) )
      {
        const propertyValue = propertyMap[propertyIndex];

        if (typeof propertyValue === 'object')
        {
          // replace arrays
          if ( propertyValue.constructor === Array )
          {
            this.propertyCache_[propertyIndex] = propertyValue;
          }
          // combine objects
          else
          {
            // remove all members which are not in propertyValue
            for ( const key in this.propertyCache_[propertyIndex] )
            {
              if ( !( key in propertyValue ) )
              {
                delete this.propertyCache_[propertyIndex][key];
              }
            }

            // assign all values from propertyValue
            Object.assign( this.propertyCache_[propertyIndex], propertyValue );
          }
        }
        else
        {
          this.propertyCache_[propertyIndex] = propertyValue;
        }
      }
    }

    //  console.log( '__propertyUpdate__', signals );

    for ( const signalName in signals )
    {
      if ( signals.hasOwnProperty(signalName) )
      {
        // Invoke all callbacks, as signalEmitted() does not. This ensures the
        // property cache is updated before the callbacks are invoked.
        this.__invokeSignalCallbacks__( Number(signalName), signals[signalName]);
      }
    }
  }

  signalEmitted(signalName, signalArgs)
  {
    this.__invokeSignalCallbacks__(signalName, signalArgs);
  }

  private __addMethod__(methodData)
  {
    const methodName = methodData[0];
    const methodIdx  = methodData[1];
    this[methodName] = ( ...methodArgs: any[] ) => {
      const args = [];
      let callback;
      for (let i = 0; i < methodArgs.length; ++i)
      {
        if (typeof methodArgs[i] === 'function')
        {
          callback = methodArgs[i];
        }
        else
        {
          args.push( methodArgs[i] );
        }
      }

      this.webChannel_.exec({
        type: QWebChannelMessageTypes.invokeMethod,
        object: this.id_,
        method: methodIdx,
        args: args
      }, (response) => {
        if (response !== undefined) {
          const result = this.__unwrapQObject__(response);
          if (callback)
          {
            (callback)(result);
          }
        }
      });
    };
  }

  private __bindGetterSetter__(propertyInfo)
  {
    const propertyIndex = propertyInfo[0];
    const propertyName  = propertyInfo[1];
    const notifySignalData = propertyInfo[2];
    // initialize property cache with current value
    // NOTE: if this is an object, it is not directly unwrapped as it might
    // reference other QObject that we do not know yet
    this.propertyCache_[propertyIndex] = propertyInfo[3];

    if (notifySignalData) {
      if (notifySignalData[0] === 1) {
        // signal name is optimized away, reconstruct the actual name
        notifySignalData[0] = propertyName + 'Changed';
      }
      this.__addSignal__(notifySignalData, true);
    }

    Object.defineProperty(this, propertyName, {
      get: () => {
        const propertyValue = this.propertyCache_[propertyIndex];
        if (propertyValue === undefined)
        {
          // This shouldn't happen
          console.warn( 'Undefined value in property cache for property "' + propertyName + '" in object ' + this.id_);
        }

        return propertyValue;
      },
      set: (value) => {
        if (value === undefined) {
          console.warn( 'Property setter for ' + propertyName + ' called with undefined value!');
          return;
        }
        this.propertyCache_[propertyIndex] = value;
        this.webChannel_.exec({
          type: QWebChannelMessageTypes.setProperty,
          object: this.id_,
          property: propertyIndex,
          value: value
        });
      }
    });
  }
}

